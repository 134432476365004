import { Controller } from "stimulus"
import ConfigureChoice from '../utilities/choices_remote.js'
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = ['carrier']

  connect() {
    const carrierChoices = new ConfigureChoice(this.carrierTarget,
      {itemSelectText: 'Select a carrier'},
      { url: 'carriers', labelField: 'name'}
    )
  }
}
