export function progress_bar(progress_bar, progress_text, progress_title,
                             current_value, total, percentage, label_text) 
{
    /**
     * @param {HTMLElement} progress_bar
     * @param {HTMLElement} progress_text
     * @param {HTMLElement} progress_title
     * @param {number} current_value
     * @param {number} total
     * @param {number} percentage
     * @param {string} label_text
     */
    progress_bar.setAttribute('aria-valuenow', current_value);
    progress_bar.setAttribute('aria-valuemin', '0');
    progress_bar.setAttribute('aria-valuemax', total);
    progress_bar.style.width = `${percentage}%`;
    progress_text.textContent = `${percentage}% (${current_value}/${total})`;
    progress_title.textContent = label_text;
}