import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

export default class DateRangeController extends Controller {
  static targets = ['inputDate', 'inputDateNoHour']

  connect() {
    if (this.hasInputDateTarget) {
      this.initializePickr(this.inputDateTarget)
    }
    if (this.hasInputDateNoHourTarget) {
      this.initializePickrNoHour(this.inputDateNoHourTarget)
    }
    
  }

  initializePickr(targ){
    flatpickr(targ, {
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      altFormat: 'm/d/Y H:i',
      altInput: true
    })
  }

  initializePickrNoHour(targ){
    flatpickr(targ, {
      dateFormat: 'Y-m-d',
      enableTime: false,
      altFormat: 'm/d/Y',
      altInput: true
    })
  }
}
