import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['modalContainer', 'modalOverlay']

  open(event) {
    this.modalOverlayTarget.style.display = 'block';
    this.modalContainerTarget.style.display = 'block';
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
  }

  close(event) {
    this.modalOverlayTarget.style.display = 'none';
    this.modalContainerTarget.style.display = 'none';
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
  }
}