import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class DocumentBundlesScan extends Controller {
  static targets = ['scanInput', 'scanForm']
  connect(){
    this.initScan();
  }

  initScan(){
    this.scanInputTarget.focus()
    this.scanInputTarget.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    });
    document.querySelector('body').addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        this.scanFormTarget.submit();
      }
    });
    this.scanInputTarget.addEventListener('click', (event) => {
      this.scanInputTarget.inputMode = 'text'
    })
    this.scanInputTarget.addEventListener('blur', (event) => {
      this.scanInputTarget.focus()
    })
  }
}
