import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['collapsable', 'newCustomerPartInput']

  connect(){
    this.newCustomerPartInputTargets.forEach((input) => {
      if(input.classList.contains('is-invalid')){
        input.closest('#newCustomerPartInputs').classList.remove('d-none')
      }
    })
  }

  toggleNewInputs(e){
    let container = e.target.closest('#newCustomerPartContainer')
    let inputs = container.querySelector('#newCustomerPartInputs')
    inputs.classList.toggle('d-none')
    if(inputs.classList.contains('d-none')){
      Array.from(inputs.querySelectorAll('input')).forEach((i) => { i.value = '' })
    }
  }

  collapsableTargetConnected(element){
    this.expand(element.querySelector('.collapsed'));
  }

  expanding(e){
    this.expand(e.target.closest('.collapsed'));
  }

  collapseAll(){
    this.collapsableTargets.forEach((c) => {
      let collapsedState = c.querySelector('.collapsed');
      let expandedState = c.querySelector('.expanded');
      //get input values
      let partNameInput = expandedState.querySelector('select#partName')
      collapsedState.querySelector('#partName').innerHTML = partNameInput.options[partNameInput.selectedIndex].text
      collapsedState.querySelector('#quantity').innerHTML = `Quantity: ${expandedState.querySelector('input#quantity').value}`

      collapsedState.classList.add('d-block');
      collapsedState.classList.remove('d-none');
      expandedState.classList.add('d-none');
      expandedState.classList.remove('d-block');
    })
  }

  expand(collapsedState){
    this.collapseAll();
    let expandedState = collapsedState.parentNode.querySelector('.expanded');
    collapsedState.classList.add('d-none');
    collapsedState.classList.remove('d-block');
    expandedState.classList.add('d-block');
    expandedState.classList.remove('d-none');
  }
}