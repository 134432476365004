import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";


export default class extends Controller {
  static targets = []
  connect() {
    this.roleId = window.roleId
  }

  update(e) {
    let checked = e.currentTarget.checked
    let ability = e.currentTarget.value
    console.log('update', e)
    this.updateAbility(ability, checked, e.currentTarget)
  }

  updateAbility(ability, checked, target){
    console.log(ability, checked, target)
    let url = checked ? window.addUrl : window.removeUrl
    Rails.ajax({
      type: "post",
      url: url,
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        let data = { ability: ability, role_id: window.roleId }
        options.data = JSON.stringify(data)
        return true
      },
      error: function(e) {
        console.log(e);
        alert('Internal error, please try again later')
        target.checked = !checked
      },
    })
  }
}
