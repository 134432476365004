import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr";

export default class DateRangeController extends Controller {
  static targets = ["inputDate"]

  connect() {
    this.initializePickr(this.inputDateTarget)
  }

  initializePickr(targ){
    flatpickr(targ, {
      mode: "range",
      locale: {
        rangeSeparator: ' - '
      }
    })
  }
}
