import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['date']

  confirmSubmit(event) {
    event.preventDefault();
    let res = confirm(`Are you sure you want to request a Shipment for Entry NO.${this.element.dataset.entry} at ${this.dateTarget.value}?`);
    
    if (res) {
      this.element.submit();
    }
  }
}
