import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import { progress_bar } from "../custom_progressbar";

export default class ScanController extends Controller {
  static get targets() {
    return ['scanBundle', 'scanForm', 'bundle', 'message', 'table', 'barcodeInput', 'progressBar', 'progressBarText',
            'progressBarTitle', 'containerBar', 'containerTable']
  }
  connect(){
    this.focusScan()
  }

  history_table(response){
    let tr = document.createElement('tr')
    let collection = JSON.parse(response.collection)
    let elem = collection[0]
    let description = document.createElement('td')
    let created = document.createElement('td')
    description.classList.add('text-center')
    description.textContent = `${elem.bundle.code} - ${elem.bundle.customer_parts[0].customer_item_number}`
    created.textContent = `${elem.formatted_date}`
    created.classList.add('text-center')
    tr.appendChild(created)
    tr.appendChild(description)
    this.tableTarget.querySelector('thead').appendChild(tr)
    this.containerTableTarget.classList.remove('d-none')
  }

  progress_bar(response) {
    let progress_bar = this.progressBarTarget
    let progress_text = this.progressBarTextTarget
    let progress_title = this.progressBarTitleTarget
    let collection = JSON.parse(response.collection)
    let elem = collection[0]
    let we = elem.bundle.warehouse_entry
    let we_total = we.receiving_count + we.scanned_count
    let percentage = we.scanned_progress
    progress_bar.setAttribute('aria-valuenow', we.scanned_count)
    progress_bar.setAttribute('aria-valuemin', '0')
    progress_bar.setAttribute('aria-valuemax', we_total)
    progress_bar.style.width = `${percentage}%`
    progress_text.textContent = `${percentage}% (${we.scanned_count}/${we_total})`
    progress_title.textContent = `ENTRY NUMBER: ${we.id}`
  }

  preventSubmit(e) {
    if(e.charCode == 13) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  scanBundle(){
    var form = this.scanFormTarget
    var formData = new FormData(form)
    const _this = this
    Rails.ajax({
      url: '/api/scan_events/scan_bundle',
      type: 'POST',
      data: formData,
      success: (response) => {
        this.barcodeInputTarget.value = ''
        if(response == ''){
          this.messageTarget.classList.add('d-none')
          return 
        }
        if (response.collection) {
          let collection = JSON.parse(response.collection)
          let we = collection[0].bundle.warehouse_entry
          _this.history_table(response)
          this.containerBarTarget.classList.remove('d-none')
          progress_bar(this.progressBarTarget, this.progressBarTextTarget, this.progressBarTitleTarget, we.scanned_count, we.receiving_count + we.scanned_count, we.scanned_progress, `ENTRY NUMBER: ${we.id}`)
        }
        this.messageTarget.classList.remove('d-none')
        this.messageTarget.classList.remove('alert-warning','alert-primary')

        if (response.error){ 
          this.messageTarget.querySelector('[data-text="text"]').textContent = response.error
          this.messageTarget.querySelector('[data-code="code"]').textContent = response.code
          this.messageTarget.classList.add('alert-warning')
          return
        }
        this.messageTarget.querySelector('[data-text="text"]').textContent = response.message
        this.messageTarget.querySelector('[data-code="code"]').textContent = response.code
        this.messageTarget.classList.add('alert-primary')
      }, error: (response) => {
        this.barcodeInputTarget.value = ''
        response.errors.forEach((element) => {
          errors.textContent = element
        })
      }
    })
    this.barcodeInputTarget.blur()
    this.barcodeInputTarget.inputMode = 'none'
    this.barcodeInputTarget.focus()
  }

  focusScan(){
    this.barcodeInputTarget.focus()

    this.barcodeInputTarget.addEventListener('input', (event) => {
      let data = event.data != undefined && event.data != null
      if(data && event.data.length > 1 && event.inputType == 'insertText'){
        this.scanBundle()
      }
    })

    document.querySelector('body').addEventListener('keypress', (event) => {
      if (event.key === "Enter") {
        event.preventDefault()
        this.scanBundle()
      }
    })

    this.barcodeInputTarget.addEventListener('click', (event) => {
      this.barcodeInputTarget.inputMode = 'text'
    })

    this.barcodeInputTarget.addEventListener('blur', (event) => {
      this.barcodeInputTarget.focus()
    })


  }

}
