import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['typeOfDocumentation', 'dynamicBundleGroups', 'dynamicCustomerParts', 'existingConfiguration', 'addBtn']

  connect() {
    let cocoonContainer = this.addBtnTarget.closest('.bundle').querySelector('.bundle_customer_parts')
    cocoonContainer.addEventListener('cocoon:after-insert', (e, insertedItem, originalEvent) => {
      let docTarget = this.typeOfDocumentationTarget
      let selectedOption = docTarget.options[docTarget.selectedIndex];
      if(selectedOption.dataset.partsFields == null) { return; }
      this.displayDocumentationFields(JSON.parse(selectedOption.dataset.partsFields), this.dynamicCustomerPartsTargets)
    });
    
    this.fetchExistingFieldsConfig();
    this.checkIfConfigChanged();
    this.setToNil();
  }

  fetchExistingFieldsConfig(){
    let existing_groups_config = this.existingConfigurationTarget.dataset.groupsFields;
    let existing_parts_config = this.existingConfigurationTarget.dataset.partsFields;
    if (existing_groups_config){
      this.displayDocumentationFields(JSON.parse(existing_groups_config), this.dynamicBundleGroupsTargets);
    }
    if(existing_parts_config){
      this.displayDocumentationFields(JSON.parse(existing_parts_config), this.dynamicCustomerPartsTargets);
    }
  }

  setToNil() {
    let persisted = this.typeOfDocumentationTarget.dataset.persisted;

    if(persisted != 'false'){
      if(this.typeOfDocumentationTarget.dataset.docTypeId === undefined){
        this.typeOfDocumentationTarget.value = ''
      }
    }
  }

  checkIfConfigChanged(){
    let docTarget = this.typeOfDocumentationTarget
    let selectedOption = docTarget.options[docTarget.selectedIndex];
    let group_config = {}
    let parts_config = {}
    if(docTarget.value == this.existingConfigurationTarget.dataset.docType){
      group_config = this.existingConfigurationTarget.dataset.groupsFields
      parts_config = this.existingConfigurationTarget.dataset.partsFields
    }else{
      group_config = selectedOption.dataset.groupFields
      parts_config = selectedOption.dataset.partsFields
    }
    this.displayDocumentationFields(JSON.parse(group_config), this.dynamicBundleGroupsTargets);
    this.displayDocumentationFields(JSON.parse(parts_config), this.dynamicCustomerPartsTargets);
  }

  displayDocumentationFields(fieldsConfig, fields){
    if(fieldsConfig.length == 0 || fields.length == 0) { return; }
    fields.forEach((field) => {
      field.classList.add('d-none')
      if(fieldsConfig[field.dataset.identifier] == 'optional'){
        let obligatoryLabel = field.querySelector('label')
        if(obligatoryLabel){
          obligatoryLabel.innerHTML = obligatoryLabel.innerHTML.replace('*','')
        }
        field.classList.remove('d-none');
      }else if(fieldsConfig[field.dataset.identifier] == 'obligatory'){
        let obligatoryLabel = field.querySelector('label')
        if(obligatoryLabel){
          obligatoryLabel.innerHTML = obligatoryLabel.innerHTML.replace('*','')
          const required_indicator = document.createElement("span")
          required_indicator.innerHTML = '*'
          required_indicator.style.textDecoration = 'underline dotted';
          obligatoryLabel.appendChild(required_indicator)
        }
        field.classList.remove('d-none');
        let input = field.querySelector('input')
        if(input) input.required = true
      }
    })
  }
}