import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class ShipmentPlansController extends Controller {
  static targets = [ "shipButton"]
  connect() {
      
  }

  enableShip() {
    var checkboxes = document.getElementsByClassName('shipment_plan_checkbox')
    var shipButton = this.shipButtonTarget
    //enable button if at least one checkbox is checked
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        shipButton.disabled = false
        return
      }
      else {
        shipButton.disabled = true
      }
    }
  }
}