import { Controller } from "stimulus"
import ConfigureChoice from '../utilities/choices_remote.js'
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = ['vendor']

  connect() {
    const vendorChoices = new ConfigureChoice(this.vendorTarget,
      {itemSelectText: 'Select a vendor'},
      { url: 'vendors', labelField: 'code_full_name'}
    )
  }
}
