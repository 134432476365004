import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import { progress_bar } from "../custom_progressbar";

export default class ScanShipmentController extends Controller {
  static get targets() {
    return ['scanBundle', 'scanForm', 'bundle', 'message', 'table', 'barcodeInput', 'progressBar', 'progressBarText',
            'progressBarTitle', 'scanMode', 'scanModeText', 'containerTable', 'progressComponentsText']
  }

  connect() {
    this.focusScan()
    console.log('connected')
  }

  focusScan(){
    this.barcodeInputTarget.focus()

    this.barcodeInputTarget.addEventListener('input', (event) => {
      let data = event.data != undefined && event.data != null
      if(data && event.data.length > 1 && event.inputType == 'insertText'){
        this.scanBundle()
      }
    })

    document.querySelector('body').addEventListener('keypress', (event) => {
      if (event.key === "Enter") {
        event.preventDefault()
        this.scanBundle()
      }
    })

    this.barcodeInputTarget.addEventListener('click', (event) => {
      this.barcodeInputTarget.inputMode = 'text'
    })

    this.barcodeInputTarget.addEventListener('blur', (event) => {
      this.barcodeInputTarget.focus()
    })
  }

  history_table(response) {
    let tr = document.createElement('tr')
    let collection = JSON.parse(response.collection)
    let elem = collection[0]
    let description = document.createElement('td')
    let created = document.createElement('td')
    description.classList.add('text-center')
    description.textContent = `${elem.bundle.code} - ${elem.bundle.customer_parts[0].customer_item_number}`
    created.textContent = `${elem.formatted_date}`
    created.classList.add('text-center')
    tr.appendChild(created)
    tr.appendChild(description)
    this.tableTarget.querySelector('thead').appendChild(tr)
    this.containerTableTarget.classList.remove('d-none')
  }

  toggleScanMode(e) {
    if (this.scanModeTarget.value == 'unload') {
      this.scanModeTarget.value = 'load'
      this.scanModeTextTarget.textContent = 'Loading'
    }
    else {
      this.scanModeTarget.value = 'unload'
      this.scanModeTextTarget.textContent = 'Unloading'
    }

  }

  preventSubmit(e) {
    if (e.charCode == 13) {
        e.preventDefault()
        e.stopPropagation()
    }
  }

  scanBundle() {
    var form = this.scanFormTarget
    var formData = new FormData(form)
    const _this = this
    formData.append('scan_type', this.scanModeTarget.value)
    Rails.ajax({
      url: '/api/scan_events/scan_bundle',
      type: 'POST',
      data: formData,
      success: (response) => {
        this.barcodeInputTarget.value = ''
        if (response.collection) {
          _this.history_table(response)
          progress_bar(this.progressBarTarget, this.progressBarTextTarget, this.progressBarTitleTarget,
                       response.shipment.loaded_quantity, response.shipment.total_quantity,
                       response.shipment.progress_percentage, `SHIPMENT: ${response.shipment.id}`)
          this.progressComponentsTextTarget.textContent = `Pieces: (${response.shipment.loaded_pieces} / ${response.shipment.total_pieces}) - Bundles: (${response.shipment.loaded_bundles} / ${response.shipment.total_bundles})`
        }
        this.messageTarget.classList.remove('d-none')
        if (response.error){
          this.messageTarget.textContent = response.error
          this.messageTarget.classList.toggle('alert-warning')
          return
        }
        this.messageTarget.textContent = response.message
        this.messageTarget.classList.toggle('alert-primary')
      }, error: (response) => {
        this.barcodeInputTarget.value = ''
        response.errors.forEach((element) => {
          errors.textContent = element
        })
      }
    })
    this.barcodeInputTarget.blur()
    this.barcodeInputTarget.inputMode = 'none'
    this.barcodeInputTarget.focus()


    this.barcodeInputTarget.addEventListener('click', (event) => {
      this.barcodeInputTarget.inputMode = 'text'
    })

    this.barcodeInputTarget.addEventListener('blur', (event) => {
      this.barcodeInputTarget.focus()
    })
  }

}