import { Controller } from "@hotwired/stimulus"
import ConfigureChoice from '../utilities/choices_remote.js'
import Rails from "@rails/ujs"
export default class extends Controller {
  static targets = ['customerPart']
  static variables = { customerChoices: ConfigureChoice }

  connect() {
    this.initializeSelect();
    this.element[this.identifier] = this;
  }
  
  initializeSelect() {
    let id = document.querySelector('[id*="customer_id"]').id
    let customer_id = document.querySelector(`#${id}`).value
    this.customerChoicesValue = new ConfigureChoice(this.customerPartTarget,
      { itemSelectText: 'Select a part' },
      { url: `customer_parts?customer_id=${customer_id}`, labelField: 'basic_info' }
    )
  }

  reloadPart(event) {
    this.customerChoicesValue.destroy();
    this.initializeSelect();
  }

}
