import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebarMenu","main"]
  connect() {}

  toggle() {
    this.sidebarMenuTarget.classList.toggle('sidebar--hidden')
    this.sidebarMenuTarget.classList.toggle('sidebar--open')
    this.mainTarget.classList.toggle('layaout--hiden__sidebar')
    this.mainTarget.classList.toggle('mobile--sidebar')
  }
}
