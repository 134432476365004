import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['select']
  static values = { text: { type: String, default: 'Press to select' } }


  connect() {
    this.initialize()
  }

  initialize() {
    this.config = {
      placeholderValue: 'Search...',
      shouldSort: false,
      searchFloor: 3,
      searchChoices: true,
      searchEnabled: true,
      duplicateItemsAllowed: false,
      searchResultLimit: 20,
      editItems:false,
      maxItemCount: 10,
      itemSelectText: `select ${ this.textValue }`
    }

    this.choices = new Choices(this.selectTarget, this.config)
  }
}
