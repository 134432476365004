import { Controller } from "@hotwired/stimulus"
import ConfigureChoice from '../utilities/choices_remote.js'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['customer']

  connect() {
    const customerChoices = new ConfigureChoice(this.customerTarget,
      {itemSelectText: 'Select customer'},
      { url: 'customers', labelField: 'code_full_name'}
    )
  }

  reloadParts(event) {
    let parts = document.querySelectorAll('.part');
    console.log(parts);
    parts.forEach(part => { part.customerPartSelect.reloadPart(event) })
  }
}
