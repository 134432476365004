import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class ZipCodeSeracherController extends Controller {
  static get targets() {
    return ['zipCodeInput', 'countryInput', 'stateInput']
  }
  connect(){
    this.findStates()
    setTimeout( () => this.searchAdress(), 300 )
  }

  searchAdress(){
    let code = this.zipCodeInputTarget.value
    let country = this.countryInputTarget.value
    let _this = this
    Rails.ajax({
      url: '/api/zip_codes/search_neighborhoods?zip_code=' + code + '&country=' + country,
      type: 'GET',
      success: function(response) {
        _this.fillInputs(response)
      }, error: function (e){
        console.log(e)
      }
    })
  }

  findStates(){
    let country = this.countryInputTarget.value
    let _this = this
    _this.stateInputTarget.innerHTML = ''
    console.log(country)
    Rails.ajax({
      url: '/api/zip_codes/countries?country=' + country,
      type: 'GET',
      success: function(response) {
        console.log(response)
        response.forEach((item) => {
          let option = document.createElement("option")
          option.value = item
          option.text = item
          _this.stateInputTarget.append(option)
        })
      }, error: function (e){
        console.log(e)
      }
    })
  }

  fillInputs(response){
    console.log(response)
    let state = response.state
    this.stateInputTarget.value = state
  }
}
