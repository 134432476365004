import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['bundles', 'customer', 'customerPartList', 'totalBundles', 'totalQuantity', 'selectTypeOfShipment','bondSerialNumber', 'normalInputs', 'fastInputs', 'groupsAmount']

  connect() {
    this.updateTotalRow();
    this.showBondSerialNumberField();
    this.bundlesTarget.addEventListener('cocoon:after-remove', (e, insertedItem, originalEvent) => {
      this.updateTotalRow()
    });
  }

  open_modal_overlay(e) {
    if(!e.isTrusted) return; //not triggered by user click
    setTimeout(()=> {
      let someElementsItems = document.querySelectorAll('.modal-trigger');
      someElementsItems[someElementsItems.length - 1].click();
    }, 100);
  }

  toggleBundleGroupInputs(e) {
    let entryType = e.target.value;
    this.removeExistingBundleGroups();
    if(entryType == 1){
      this.normalInputsTarget.style.display = 'block';
      this.fastInputsTarget.style.display = 'none';
    }else if (entryType == 2){
      this.normalInputsTarget.style.display = 'none';
      this.fastInputsTarget.style.display = 'block';
    } 
  }

  batchCreateBundleGroups(e){
    e.preventDefault();
    this.removeExistingBundleGroups();
    let amount = this.groupsAmountTarget.value
    let msg = `Are you sure you want to create ${amount} bundle groups?`;
    if (confirm(msg) == true) {
      for(let i = 0; i < amount; i++){
        this.normalInputsTarget.firstChild.click();
      }
      Array.from(document.querySelectorAll('input.bundle_qty')).forEach((i) => {i.value = 1})
      Array.from(document.querySelectorAll('select.type_of_doc')).forEach((i) => {i.value = ''})
    }
    let bundleGroupRows = document.querySelectorAll('.total_bundle_qty')
    this.totalBundlesTarget.textContent = bundleGroupRows.length
    Array.from(bundleGroupRows).forEach((i) => {i.textContent = '1 Piece(s)'})
  }

  removeExistingBundleGroups(){
    Array.from(document.querySelectorAll('.bundle.nested-fields')).forEach(node => {node.remove()})
    this.totalBundlesTarget.textContent = '0';
    this.totalQuantityTarget.textContent = '0';
  }

  updateTotalRow() {
    let total_pieces = 0;
    let total_bundles = 0;

    document.querySelectorAll('.total_parts_qty').forEach((product_qty) => {
      if(product_qty.parentNode.getAttribute("style") != 'display: none;') {
        let product_qty_value = parseInt(product_qty.getAttribute('data-total-quantity'))
        total_pieces += product_qty_value;
      }
    });
    document.querySelectorAll('.total_bundle_qty').forEach((bundle_qty) => {
      if(bundle_qty.parentNode.getAttribute("style") != 'display: none;') {
        let bundle_qty_value = parseInt(bundle_qty.getAttribute('data-total-quantity'))
        total_bundles += bundle_qty_value;
      }    
    });

    this.totalBundlesTarget.textContent = total_bundles.toString();
    this.totalQuantityTarget.textContent = total_pieces.toString();
  }

  showBondSerialNumberField() {
    const IN_BOND_STATUS = "In bond";

    this.bondSerialNumberTarget.parentNode.classList.toggle('d-none', this.selectTypeOfShipmentTarget.value != IN_BOND_STATUS);
    this.selectTypeOfShipmentTarget.value != IN_BOND_STATUS ? this.bondSerialNumberTarget.value = '' : null;
  }
}