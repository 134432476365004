
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'addBtn'
  ]

  connect() {
    console.log('shipment-form.js')
    this.addBtnTarget.setAttribute('data-association-insertion-node', function(e){
      const element = e.closest('.shipment')
      return element.querySelector('.shipment_parts')
    })
  }
}